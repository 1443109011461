import React, { useEffect, useState } from "react";
import "../Style.css/style.css";
import {twitter, one, two, three, four,
    five, six, seven, eight, nine,
    ten, eleven, twelve, thirteen,
    fourteen, fifteen, sixteen,
    seventeen, eighteen, nineteen,
    twenty, twentyOne, twentyTwo,
    twentyThree,
    twentyFour,
    twentyFive,
    twentySix,
    twentySeven,
    twentyEight,
    twentyNine,
    thirty,
    thirtyOne,
    thirtyTwo,
    thirtyThree,
    thirtyFour,
    thirtyFive,
    thirtySix,
    thirtySeven,
    thirtyEight,
    thirtyNine,
    forty,
    fortyOne,
    fortyTwo,
    fortyThree,
    fortyFour,
    fortyFive,
    fortySix,
    fortySeven,
    fortyEight,
    fortyNine,
    fifty,
    fiftyOne,
    fiftyTwo,
    fiftyThree,
    fiftyFour,
    fiftyFive,
    fiftySix,
    fiftySeven,
    fiftyEight,
    fiftyNine,
    sixty,
    t1,
    freemint,
    cat1,
    cat2,
    cat3,
    cat4,
    cat5
} from "./imageImports.jsx";

import { ConnectButton } from '@rainbow-me/rainbowkit';
import { 
  useAccount, 
  usePrepareContractWrite, 
  useContractWrite,
  useContractRead,
  useWaitforTransaction
} from "wagmi";
import contractABI from "../contracts/contractAbi.json";
import contract_address from "../constant/address.jsx";
import { ethers, BigNumber } from "ethers";
import Whitelist from "../lib/Whitelist.ts";

const gasLimit = 210000;

const Main = () => {
  const [ totalMinted, setTotalMinted ]= React.useState(0);
  const { isConnected } = useAccount();
  console.log("isConnected", isConnected);
  //const [ notOpen ] = React.useState(true);
  

  ///////Minting configs/////////////////////////////////////
const { config: mintOneConfig } = usePrepareContractWrite({
  address: contract_address,
  abi: contractABI.abi,
  functionName: 'mint',
  args: [1],
  overrides: {
    gasLimit: BigNumber.from(gasLimit),
    value: ethers.utils.parseEther('0.009'),
  } 
});
console.log(mintOneConfig);

const { config: mintTwoConfig } = usePrepareContractWrite({
  address: contract_address,
  abi: contractABI.abi,
  functionName: 'mint',
  args: [2], // Adjust the quantity as needed
  overrides: {
    gasLimit: BigNumber.from(gasLimit * 2), // Adjust the gas limit accordingly
    value: ethers.utils.parseEther('0.018'), // Adjust the price accordingly
  }
});

const { 
  send: mintOneToken,
  isLoading: isLoadingOne,
  isSuccess: isSuccessOne,
  data: mintDataOne,      
} = useContractWrite({
  config: mintOneConfig,
});

const { 
  send: mintTwoTokens ,
  isLoading: isLoadingTwo,
  isSuccess: isSuccessTwo,
  data: mintDataTwo,
} = useContractWrite({
  config: mintTwoConfig,
});

console.log("mintOneToken:", mintOneToken);
console.log("mintTwoTokens:", mintTwoTokens);
///////////////////////////////////////////////////////


// const { config: isWhitelistMintEnabled } = useContractRead ({
//   address: contract_address,
//   abi: contractABI.abi,
//   functionName: 'whitelistMintEnabled',
//   watch: true,
  
// });



// console.log(isWhitelistMintEnabled, "isWhitelistMintEnabled");

// const { config: whitelistMintConfig } = usePrepareContractWrite({
//   address: contract_address,
//   abi: contractABI.abi,
//   functionName: 'whitelistMint',
//   args: [1],
//   overrides: {
//     gasLimit: ethers.BigNumber.from(gasLimit),
//     value: ethers.utils.parseEther('0.03'),
//   }
// });

// const { data: whitelistMintData, write: whiteListMint, isLoading: isWhiteListLoading, isSuccess: isWhiteListSuccess }  = useContractWrite(whitelistMintConfig);

const { data: totalSupplyData } = useContractRead ({
  address: contract_address,
  abi: contractABI.abi,
  functionName: 'totalSupply',
  watch: true,
  onError(error) {
    console.log('Error', error)
    },
});


React.useEffect(() => {
  if (totalSupplyData) {
    setTotalMinted(totalSupplyData.toNumber());
  }
}, [totalSupplyData]);
 

const {data: isPaused } = useContractRead({
  address: contract_address,
  abi: contractABI.abi,
  functionName: 'paused',
  watch: true,
});

  return (
    <>
    <a href="https://twitter.com/meowhelpline" target="_blank">
      <button className="t-icon">
      <img src={twitter} alt="" title="" />
      </button>
    </a>
      <div className="connect">
      <ConnectButton />
      </div>;
      <section className="top-title-block">
        <div className="t-img">
          <img src={t1} alt="" title="" />
        </div> 
        <div className="t-img2">
          <img src={freemint} alt="" title="" />
        </div>
      </section>

      <section className="minted-wrap">
        <div className="container">
          <div className="row">
            <div className="sol-sm-12">
              <div className="mint-block">
                {/* <h3>0000 / 9999</h3> */}
                <h3>
                  {" "}
                   { totalMinted }  / 9999{" "}
                </h3>
                <h5>Have been minted.</h5>
                    {isPaused && <h5>Public Sale not open right Meow!</h5>}
                <div className="m-btns">
                <button onClick={() => { console.log('Mint 1 Meow clicked'); mintOneToken(); }} disabled={!isConnected}>
  Mint 1 Meow
</button>

<button onClick={() => { console.log('Mint 2 Meows clicked'); mintTwoTokens(); }} disabled={!isConnected}>
  Mint 2 Meows
</button>

                  <br />
                  {(isLoadingOne || isLoadingTwo) && <h6> Check Wallet </h6>}
                  {isSuccessOne && <h6>Transaction 1: {JSON.stringify(mintDataOne)}</h6>}
                  {isSuccessTwo && <h6>Transaction 2: {JSON.stringify(mintDataTwo)}</h6>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="help-wrap">
        <div className="container">
          <div className="row ">
            <div className="col-sm-12 help-xs-row">
              <div className="help-title">
                <h5>WE</h5>
                <h5>ARE</h5>
                <h5>HERE</h5>
                <h5>TO</h5>
                <h5>HELP!</h5>
              </div>
              <div className="cat-img-row">
                <div className="row row-cols-5">
                  <div className="col">
                    <div className="c-img-block">
                      <img src={cat1} alt="" title="" />
                    </div>
                  </div>
                  <div className="col">
                    <div className="c-img-block">
                      <img src={cat2} alt="" title="" />
                    </div>
                  </div>
                  <div className="col">
                    <div className="c-img-block">
                      <img src={cat3} alt="" title="" />
                    </div>
                  </div>
                  <div className="col">
                    <div className="c-img-block">
                      <img src={cat4} alt="" title="" />
                    </div>
                  </div>
                  <div className="col">
                    <div className="c-img-block">
                      <img src={cat5} alt="" title="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="help-text">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="hc-block">
                  <h3>Here to help everyone live in the MEOW!</h3>
                  <p>
                    A free mint of 9999 Meow Helpline specialists living on the
                    ETH blockchain with the sole mission to spread the MEOW!{" "}
                    <br />
                    No discord. On Twitter right meow!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="cat-gall">
        <div className="gall-row">
          <div className="cat-img-block">
            <img src={one} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={two} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={three} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={four} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={five} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={six} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={seven} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={eight} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={nine} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={ten} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={eleven} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={twelve} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={thirteen} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={fourteen} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={fifteen} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={sixteen} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={seventeen} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={eighteen} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={nineteen} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={twenty} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={twentyOne} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={twentyTwo} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={twentyThree} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={twentyFour} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={twentyFive} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={twentySix} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={twentySeven} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={twentyEight} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={twentyNine} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={thirty} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={thirtyOne} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={thirtyTwo} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={thirtyThree} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={thirtyFour} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={thirtyFive} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={thirtySix} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={thirtySeven} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={thirtyEight} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={thirtyNine} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={forty} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={fortyOne} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={fortyTwo} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={fortyThree} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={fortyFour} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={fortyFive} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={fortySix} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={fortySeven} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={fortyEight} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={fortyNine} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={fifty} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={fiftyOne} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={fiftyTwo} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={fiftyThree} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={fiftyFour} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={fiftyFive} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={fiftySix} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={fiftySeven} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={fiftyEight} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={fiftyNine} alt="im" />
          </div>
          <div className="cat-img-block">
            <img src={sixty} alt="im" />
          </div>
        </div>
      </section>
    </>
  );
};

export default Main;

import './App.css';
import Main from './components/Main';
import '@rainbow-me/rainbowkit/styles.css';
import {
  darkTheme,
  getDefaultWallets,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import {  configureChains, createClient, WagmiConfig } from 'wagmi';
import { mainnet, goerli, sepolia } from 'wagmi/chains';
import { infuraProvider } from 'wagmi/providers/infura';
import { publicProvider } from 'wagmi/providers/public';

const { chains, provider, webSocketProvider } = configureChains( 
  [goerli],
  [infuraProvider( { apiKey: process.env.INFURA_PROVIDER_KEY }), 
   publicProvider()
  ],
);

const { connectors } = getDefaultWallets( {
  appName: "Meow",
  chains,
});

const wagmiClient = createClient ( {
  autoConnect: true,
  provider,
  connectors,
  webSocketProvider,
});

function App() {
  return (
    //<div className="App">
    <WagmiConfig client={wagmiClient}>
    <RainbowKitProvider chains={chains}
      theme={darkTheme({
      accentColor: '#0099CC', 
      borderRadius: 'large',
      fontStack: 'rounded',
      overlayBlur: 'small',
})}>
      <Main/>
    </RainbowKitProvider>
  </WagmiConfig>
  );
}

export default App;

